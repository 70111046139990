$colors: (
  '0': #ffffff,
  '1': repeating-linear-gradient(-45deg, #f1f1f1, #a5a5a5 1px, #ffffff 2px, #eda8a7 10px),
  '2': repeating-linear-gradient(-45deg, #f1f1f1, #a5a5a5 1px, #ffffff 2px, #ece1a5 10px),
  '3': repeating-linear-gradient(-45deg, #f1f1f1, #a5a5a5 1px, #ffffff 2px, #bdeda5 10px),
  '4': repeating-linear-gradient(-45deg, #f1f1f1, #a5a5a5 1px, #ffffff 2px, #a6ece6 10px),
  '5': repeating-linear-gradient(-45deg, #f1f1f1, #a5a5a5 1px, #ffffff 2px, #b3a6ee 10px),
);

@each $name, $color in $colors {
  .absence-color-#{$name} {
    background: $color !important;
  }
}
