@use '@angular/material' as mat;
@use 'ag-grid-community/styles' as ag;

@import 'fontsource-montserrat/index.css';
@import 'fontsource-montserrat/700.css';

@import 'variables';
@import 'project-colors';
@import 'absence-colors';

@include ag.grid-styles(
  (
    theme: 'material',
    --ag-font-family: $font-family,
  )
);

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// created with http://mcg.mbitson.com/
// vars in variables.scss
$primary: mat.define-palette($md-primary);
$accent: mat.define-palette($md-accent);
$warn: mat.define-palette($md-warn);

$caption-level: mat.define-typography-level(
  $font-family: 'Montserrat, monospace',
  $font-weight: 400,
  $font-size: 0.8rem,
  $line-height: 0.8,
  $letter-spacing: normal,
);

$custom-typography: mat.define-typography-config(
  $font-family: 'Montserrat, monospace',
  $caption: $caption-level,
);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: $custom-typography,
    density: -1,
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// todo: only apply for used components
@include mat.all-component-themes($candy-app-theme);

// This makes sure that the typography is also included in components
// which don't include typography by default. For example cards.
@include mat.all-component-typographies($custom-typography);

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Make form-fields look like before v15 update
.mat-mdc-form-field {
  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mdc-text-field--disabled.mdc-text-field--filled,
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mat-mdc-text-field-wrapper,
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
  .mat-mdc-form-field-icon-suffix,
  .mdc-floating-label {
    font-size: 14px;
  }

  .mat-mdc-select {
    font-size: 14px;
    padding: 0;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 0;
  }
}

.mdc-checkbox__checkmark-path {
  color: white;
}

// Fixes the dialog feeling too tight
// https://gitlab.com/triarc-labs/christen/cal/uploads/2a8f2899836956e2fd5cb2751a6c9965/image.png
.mat-mdc-dialog-surface {
  @apply p-6;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Montserrat, 'Helvetica Neue', sans-serif;
}

.mat-icon {
  color: $actionColor;
}

.ag-header-cell-text {
  color: $color-text-label;
  font-size: 14px;
  font-weight: 600;
}

.ag-theme-material {
  font-family: $font-family;

  .ag-header-row {
    font-weight: normal;
  }

  .ag-row {
    color: $color-text-dark;
  }
}

// https://stackoverflow.com/questions/35578083/what-is-the-equivalent-of-ngshow-and-nghide-in-angular-2
[hidden] {
  display: none !important;
}

.snack-bar-error {
  background-color: red;
  color: white;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none;
}

.mat-mdc-fab.white {
  background-color: white;
}

.td-dialog-actions {
  .mat-mdc-button.mat-accent {
    color: black;
    background-color: $christenGreen;
  }

  button {
    text-transform: none !important;
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  @apply border-0 ring-0;
}

h3,
h1,
h2 {
  color: $christenGreen;
}

body {
  .mat-mdc-button.mat-mdc-button-base,
  .mat-mdc-raised-button.mat-mdc-button-base,
  .mat-mdc-unelevated-button.mat-mdc-button-base {
    border-radius: 18px;
    height: 36px;
  }

  .mat-accordion .mat-expansion-panel:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .mat-expansion-panel {
    border-radius: 8px;
  }

  .mat-mdc-raised-button,
  .mat-mdc-unelevated-button {
    mat-icon {
      color: #ffffff;
    }
  }

  .mat-mdc-raised-button.mat-unthemed,
  .mat-mdc-unelevated-button.mat-unthemed {
    mat-icon {
      color: $actionColor;
    }
  }

  .mdc-text-field--disabled {
    .mdc-text-field__input {
      color: $color-text-label;
    }

    .mdc-floating-label {
      color: $color-text-muted;
    }
  }

  .mat-mdc-select-disabled {
    .mat-mdc-select-value {
      color: $color-text-label;
    }

    .mat-mdc-select-arrow {
      color: transparent;
    }
  }

  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: transparent;
  }

  .mat-mdc-select-disabled {
    .mat-mdc-select-arrow-wrapper {
      display: none;
    }
  }

  .mat-button-toggle {
    background-color: transparent;
    color: $christenGreen;

    &.readonly {
      color: #888;
    }
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-disabled.mat-button-toggle-checked,
  .mat-button-toggle-checked,
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background-color: $christenGreen;
    color: #fff;

    &.readonly {
      background-color: #888 !important;
    }
  }
}

.icon-header {
  display: flex;

  & > mat-icon {
    margin-right: 10px;
    color: $christenGreen;
  }
}

.header-value-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.extra-data-sizing.mat-expansion-panel-header {
  height: 70px;

  // Bring it back to normal when expanded
  &.mat-expanded {
    height: 60px;
  }
}

input[type='time']::-webkit-calendar-picker-indicator {
  filter: opacity(0.54);
}

input.no-ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type='time'].no-ampm::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.ag-ltr .ag-group-expanded {
  margin-right: 10px;
}

::ng-deep {
  .mdc-line-ripple::before {
    border-bottom: none;
  }
}

.mat-mdc-form-field.no-underline {
  .mdc-line-ripple::before {
    border-bottom: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.cdk-overlay-pane {
  div.mat-mdc-menu-panel.conflict-menu {
    max-width: max-content;
  }
}
