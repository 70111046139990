$color-navbar: #333333;
$color-resource-task-planned-confirmed: #d8ebcf;
$color-resource-task-emergency: #e6b0ae;
$color-resource-task-pending: #f0f0f0;

$color-box-background: #f0f0f0;
$color-background: #ffffff;

$color-text-light: #ffffff;
$color-text-dark: #000000;
$color-text-label: #2b2b2b;
$color-text-muted: #aaaaaa;

$christenGreen: #5d9528;
$primaryColor: #b6d7a8;
$actionColor: #757575;
$font-family: Montserrat, 'Helvetica Neue', sans-serif;

$color-wp-running: #d8ebcf;
$color-wp-paused: #ebcfcf;
$color-wp-completed: #cad2eb;

$color-sidebar-bg: #e5e5e5;
$color-sidebar: #757575;

$grid-bg-color: #e2e2e2;
$grid-project-task-work-package-height: calc(50vh - 126px);

$tablet-screen-size-width: 1024px;
$tablet-screen-size-height: 600px;

$task-state-color-open: green;
$task-state-color-planned: orange;
$task-state-color-closed: grey;

$stage-color-blue: #c8e4f6;
$stage-color-red: #f9dad9;
$stage-color-green: #97d781;

$driver-toolbar-height: 64px;

$icon-default-color: #757575;

$md-accent: (
  50: #f6faf5,
  100: #e9f3e5,
  200: #dbebd4,
  300: #cce3c2,
  400: #c1ddb5,
  500: #b6d7a8,
  600: #afd3a0,
  700: #a6cd97,
  800: #9ec78d,
  900: #8ebe7d,
  A100: #ffffff,
  A200: #ffffff,
  A400: #e7ffde,
  A700: #d4ffc4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-warn: (
  50: #fdf5f5,
  100: #fbe6e6,
  200: #f8d6d6,
  300: #f5c6c6,
  400: #f2b9b9,
  500: #f0adad,
  600: #eea6a6,
  700: #ec9c9c,
  800: #e99393,
  900: #e58383,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #fff0f0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-primary: (
  50: #ecf2e5,
  100: #cedfbf,
  200: #aeca94,
  300: #8eb569,
  400: #75a548,
  500: #5d9528,
  600: #558d24,
  700: #4b821e,
  800: #417818,
  900: #30670f,
  A100: #bdff9c,
  A200: #9bff69,
  A400: #79ff36,
  A700: #68ff1c,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
