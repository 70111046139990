$colors: #ffffff, rgb(241, 255, 251), rgb(203, 255, 245), rgb(0, 232, 255),
  rgb(74, 136, 210), rgb(141, 180, 226), rgb(197, 217, 241), rgb(247, 150, 70),
  rgb(250, 191, 143), rgb(252, 219, 192), rgb(79, 194, 60), rgb(143, 206, 74),
  rgb(187, 209, 139), rgb(255, 0, 255), rgb(200, 3, 255), rgb(146, 122, 174),
  rgb(177, 160, 199), rgb(222, 216, 232), rgb(166, 166, 166), rgb(191, 191, 191),
  rgb(213, 213, 213), rgb(255, 202, 33), rgb(255, 255, 79), rgb(255, 84, 82),
  rgb(255, 129, 129);

@each $current-color in $colors {
  $i: index($colors, $current-color);
  .project-color-#{$i - 1} {
    background-color: $current-color !important;
  }
}
